<template> 
  <div>
    <el-row class="input Part">
      <el-button size="mini" type="primary" @click="addWarehouse" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AddWarehouse">添加库房</el-button>
      <el-button size="mini" type="primary" @click="updateWarehouse" v-if="authe.Hotel_RegionalDivision_RoomLibrary_ModifyWarehouse">修改库房</el-button>
        <el-dialog :title="wareTitle" :visible.sync="dialogWarehouse" @close="dialogWarehouseClose" :close-on-click-modal="false" v-loading="loading" width="400px">
          <el-form size="mini" label-width="70px">
            <el-form-item label="库房状态" v-if="wareTitle=='库房修改'">
              <el-select v-model="addData.hsState" style="width:280px">
                <el-option label="" value=""></el-option>
                <el-option label="正常" value="正常"></el-option>
                <el-option label="停用" value="停用"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="addData.hsAddCommunity" style="width:280px"></el-input>
            </el-form-item>
            <el-form-item label="备注"> 
              <el-input v-model="addData.hsHouseNote" type="textarea" :rows="2" style="width:280px"></el-input>
            </el-form-item>
          </el-form>

          <div slot="footer">
            <el-button size="mini" @click="dialogWarehouse=false">关闭</el-button>
            <el-button size="mini" type="primary" @click="saveRoom">保存</el-button>
          </div>
        </el-dialog>
      <!--  筛选框 -->
      <el-form inline label-width="75px">
        <el-form-item label="库房名称">
          <el-input @input="searchOnkeyup(getHomeData)" v-model="homeParms.hsAddCommunity" clearable size="mini" style="width:140px"></el-input>
        </el-form-item>
        <el-form-item label="备注描述">
          <el-input @input="searchOnkeyup(getHomeData)" v-model="homeParms.hsHouseNote" clearable size="mini" style="width:140px"></el-input>
        </el-form-item>
        <el-form-item label="库房状态">
          <el-select @change="getHomeData(1)" v-model="homeParms.hsState" clearable size="mini" style="width:140px">
            <el-option label="" value=""></el-option>
            <el-option label="正常" value="正常"></el-option>
            <el-option label="停用" value="停用"></el-option>
            <el-option label="注销" value="注销"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table :data="homeData.records" @row-click="homeDataClick" @row-dblclick="homeDataDblclick" :height="tableHeight" highlight-current-row style="width: 100%">
      <el-table-column prop="hsAddCommunity" align="center" label="库房名称"></el-table-column>
      <el-table-column prop="hsHouseNote" align="center" label="备注描述"></el-table-column>
      <el-table-column prop="hsState" align="center" label="状态"></el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[5,10,20,40]"
      :page-size="homeParms.limit"
      :current-page="homeParms.cursor"
      :total="homeData.total"
      align="center"
      background
      @current-change="meterCurChange"
      @size-change="meterSizeChange"
    ></el-pagination>
    <el-dialog title="库房详细信息" v-loading="loading" :visible.sync="dialogInfo" @close="dialogInfoClose" :close-on-click-modal="false" width="1100px">
      <el-tabs v-model="activeName" style="height:550px;overflow-y:auto">
        <el-tab-pane label="库房详细" name="public">
          <el-form inline>
            <el-row>
              <el-col :span="6">
                <el-form-item label="库房名称: " label-width="80px">
                  {{homeRow.hsAddCommunity}}
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="分类: " label-width="80px">
                  {{homeRow.hsAddCity}}
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态: " label-width="80px">
                  {{homeRow.hsState}}
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="联系人: " label-width="80px">
                  {{homeRow.keyNumber}}
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>
              <!-- <el-col :span="6">
                <el-form-item label="联系电话: " label-width="80px">
                  {{homeRow.houseEntrust4rent}}
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="库房创建人: " label-width="80px">
                  {{homeRow.suStaffName}}
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="备注: " label-width="80px">
                  {{homeRow.hsHouseNote}}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="耗材管理" v-if="false && authe.Hotel_RegionalDivision_RoomLibrary_ConsumablesManagement" name="consumables">
          <el-row>
            <el-button size="mini" type="primary" @click="addSupplies" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AddSupplies">添加耗材</el-button>
            <el-button size="mini" type="primary" @click="updateSupplies" v-if="authe.Hotel_RegionalDivision_RoomLibrary_ModifySupplies">修改耗材</el-button>
            <el-button size="mini" type="primary" @click="moveSupplies" v-if="authe.Hotel_RegionalDivision_RoomLibrary_MigrateSupplies">迁移耗材</el-button>
            <el-button size="mini" type="primary" @click="useSupplies" v-if="authe.Hotel_RegionalDivision_RoomLibrary_UseSupplies">使用耗材</el-button>
            <el-button size="mini" type="primary" @click="subSupplies" v-if="authe.Hotel_RegionalDivision_RoomLibrary_IncreaseSupplies">增加耗材数量</el-button>
              <!-- 添加修改耗材弹窗 -->
              <el-dialog :title="supTitle" :visible.sync="dialogAddSup" :close-on-click-modal="false" @close="dialogAddSupClose" v-loading="loading" width="730px" append-to-body>
                <p style="color:red">温馨提示：若系统中该库房已存在该耗材，请直接修改耗材数量，无须重复添加。</p>
                &nbsp;&nbsp;耗材归属&nbsp;<el-input :value="homeRow.hsAddCommunity" disabled size="mini" style="width:280px"></el-input>
                <el-form size="mini" label-width="75px" inline style="margin-top:10px" :rules="rules" :model="addSupData" ref="addSup">
                  <el-form-item label="耗材类型" prop="supType">
                    <el-select v-model="addSupData.supType" style="width:140px">
                      <el-option label="" value=""></el-option>
                      <el-option label="维修" value="维修"></el-option>
                      <el-option label="办公" value="办公"></el-option>
                      <el-option label="其他" value="其他"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="名称" prop="supName">
                    <el-input v-model="addSupData.supName" style="width:140px"></el-input>
                  </el-form-item>
                  <el-form-item label="品牌">
                    <el-input v-model="addSupData.supBrand" style="width:140px"></el-input>
                  </el-form-item>
                  <el-form-item label="型号">
                    <el-input v-model="addSupData.supModel" style="width:140px"></el-input>
                  </el-form-item>
                  <el-form-item label="价格" prop="supPrice">
                    <el-input v-model="addSupData.supPrice" type="tel" oninput="value=value.replace(/[^\d.]/g,'')" style="width:140px"></el-input>
                  </el-form-item>
                  <el-form-item label="数量" prop="supNum" v-if="supTitle=='添加耗材'">
                    <el-input v-model="addSupData.supNum" type="tel" oninput="value=value.replace(/[^\d]/g,'')"  style="width:140px"></el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="addSupData.supRemark" style="width:365px"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="dialogAddSup=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveSupplies">保存</el-button>
                </div>
              </el-dialog>
            
              <el-dialog title="迁移耗材" :visible.sync="dialogMoveOut" @close="dialogSupMoveOut" :close-on-click-modal="false" append-to-body v-loading="loading" width="400px">
                <el-form size="mini" label-width="70px">
                  <el-form-item label="迁出库房">
                    <el-input :value="suppliesRow.hsAddCommunity" disabled style="width:280px"></el-input>
                  </el-form-item>
                  <el-form-item label="迁入库房">
                    <el-input :value="roomAddress" @click.native="selectAddressBelong('选择库房')" style="width:280px"></el-input>
                  </el-form-item>
                  <el-form-item label="迁移数量">
                    <el-input v-model="moveNum" style="width:280px" type="tel" oninput="value=value.replace(/[^\d]/g,')"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="dialogMoveOut=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveMoveOut">保存</el-button>
                </div>
              </el-dialog>
            
              <el-dialog title="使用耗材" :visible.sync="dialogUse" @close="dialogUseClose" :close-on-click-modal="false" append-to-body v-loading="loading" width="400px">
                <el-form size="mini" label-width="70px">
                  <el-form-item label="使用地址">
                    <el-input :value="roomAddress" @click.native="selectAddressBelong('选择地址')" style="width:280px"></el-input>
                  </el-form-item>
                  <el-form-item label="使用数量">
                    <el-input v-model="useNum" style="width:280px" type="tel" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                  </el-form-item>
                  <el-form-item label="使用原因">
                    <el-input v-model="useRemark" style="width:280px"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="dialogUse=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveUse">保存</el-button>
                </div>
              </el-dialog>
            
              <el-dialog title="增减耗材" :visible.sync="dialogSubSupplies" @close="dialogSubSuppliesClose" :close-on-click-modal="false" append-to-body v-loading="loading" width="400px">
                <el-form size="mini" label-width="70px">
                  <el-form-item label="现有数量">
                    <el-input :value="suppliesRow.supNum" disabled style="width:280px"></el-input>
                  </el-form-item>
                  <el-form-item label="增减数量">
                    <el-input v-model="subOrAddNum" style="width:280px" type="tel" oninput="value=value.replace(/[^\d-]/g,'')"></el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="subOrAddRemark" style="width:280px"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="dialogSubSupplies=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveSubSupplies">保存</el-button>
                </div>
              </el-dialog>
          </el-row>
          <el-form inline label-width="70px">
            <el-form-item label="耗材类型">
              <el-select @change="getSupplies(1)" v-model="suppliesParams.supType" clearable size="mini" style="width:140px">
                <el-option label="" value=""></el-option>
                <el-option label="维修" value="维修"></el-option>
                <el-option label="办公" value="办公"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="耗材名称">
              <el-input @input="searchOnkeyup(getSupplies)" v-model="suppliesParams.supName" clearable size="mini" style="width:140px"></el-input>
            </el-form-item>
            <el-form-item label="品牌">
              <el-input @input="searchOnkeyup(getSupplies)" v-model="suppliesParams.supBrand" clearable size="mini" style="width:140px"></el-input>
            </el-form-item>
            <el-form-item label="型号">
              <el-input @input="searchOnkeyup(getSupplies)" v-model="suppliesParams.supModel" clearable size="mini" style="width:140px"></el-input>
            </el-form-item>
          </el-form>
            <el-table :data="suppliesData.records" @row-click="suppliesDataClick" @row-dblclick="suppliesDblclick" :row-class-name="tableRowShowInput" ref="supTable" height="350px" highlight-current-row size="mini" style="width: 100%">
              <el-table-column prop="hsAddCommunity" align="center" label="库房"></el-table-column>
              <el-table-column prop="supType" align="center" label="类型"></el-table-column>
              <el-table-column prop="supName" align="center" label="名称"></el-table-column>
              <el-table-column prop="supBrand" align="center" label="品牌"></el-table-column>
              <el-table-column prop="supModel" align="center" label="型号"></el-table-column>
              <el-table-column prop="supPrice" align="center" label="价格"></el-table-column>
              <el-table-column prop="supNum" align="center" label="数量"></el-table-column>
              <el-table-column prop="supRemark" align="center" label="备注"></el-table-column>
              <el-table-column prop="supImgNum" align="center" label="图片"></el-table-column>
            </el-table>
            <el-pagination
              background
              :page-size="suppliesParams.limit"
              align="center"
              :current-page="suppliesParams.cursor"
              layout="prev, pager, next"
              @current-change="suppCurChange"
              :total="suppliesData.total"
              style="margin-top: 10px">
            </el-pagination>
            <el-dialog title="耗材详细信息" :visible.sync="dialogDblclick" :close-on-click-modal="false" v-loading="loading" width="730px" append-to-body>
              &nbsp;&nbsp;耗材归属&nbsp;<el-input :value="homeRow.hsAddCommunity" disabled size="mini" style="width:280px"></el-input>
              <el-form size="mini" label-width="75px" inline style="margin-top:10px">
                <el-form-item label="耗材类型">
                  <el-input :value="suppliesRow.supType" disabled style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="名称">
                  <el-input :value="suppliesRow.supName" disabled style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="品牌">
                  <el-input :value="suppliesRow.supBrand" disabled style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="型号">
                  <el-input :value="suppliesRow.supModel" disabled style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="价格">
                  <el-input :value="suppliesRow.supPrice" disabled type="tel" oninput="value=value.replace(/[^\d.]/g,'')" style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="数量">
                  <el-input :value="suppliesRow.supNum" disabled type="tel" oninput="value=value.replace(/[^\d]/g,'')"  style="width:140px"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input :value="suppliesRow.supRemark" disabled style="width:365px"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button size="mini" type="primary" @click="supPhoto">查看图片</el-button>
                </el-form-item>
              </el-form>
              <el-table :data="supFollow.records" @row-dblclick="supFolDblclick" height="210px" highlight-current-row size="mini" style="width: 100%">
                <el-table-column prop="jhfFollowTime" align="center" show-overflow-tooltip label="跟进时间"></el-table-column>
                <el-table-column prop="suStaffName" align="center" label="跟进人"></el-table-column>
                <el-table-column prop="jhfPaymentWay" align="center" label="跟进类型"></el-table-column>
                <el-table-column prop="jhfFollowRemark" align="center" show-overflow-tooltip label="跟进内容"></el-table-column>
              </el-table>
              <el-pagination
                background
                :page-size="subDbLimit"
                align="center"
                :current-page="subDbCursor"
                layout="prev, pager, next"
                @current-change="supFollCurChange"
                :total="supFollow.total">
              </el-pagination>
              <div slot="footer" align="center">
                <el-button size="mini" type="primary" @click="supPrv">上一条</el-button>
                <el-button size="mini" type="primary" @click="supNext">下一条</el-button>
              </div>
            </el-dialog>
            <el-dialog title="跟进详细信息" :visible.sync="dialogSupFol" :close-on-click-modal="false" width="500px" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tr>
                  <td>跟进时间</td>
                  <td style="color:blue;" colspan="3">{{ supFolRow.jhfFollowTime }}</td>
                </tr>
                <tr>
                  <td>跟进人</td>
                  <td style="color:blue;">{{ supFolRow.suStaffName }}</td>
                  <td>跟进类型</td>
                  <td style="color:blue;">{{ supFolRow.jhfPaymentWay }}</td>
                </tr>
                <tr>
                  <td>跟进内容</td>
                  <td style="color:blue;" colspan="3">{{ supFolRow.jhfFollowRemark }}</td>
                </tr>
              </table>
            </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="资产管理" name="asset" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AssetManagement">
          <el-row>
            <el-button size="mini" type="primary" @click="addAssetsClick" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AddingAssets">添加资产</el-button>
            <el-button size="mini" @click="fixAssetsClick" type="primary" v-if="authe.Hotel_RegionalDivision_RoomLibrary_ModifyAssets">修改资产</el-button>
            <el-button size="mini" @click="moveOutClick" type="primary" v-if="authe.Hotel_RegionalDivision_RoomLibrary_MigrateAssets">迁移资产</el-button>
            <el-button size="mini" @click="printCodeClick" type="primary" v-if="authe.Hotel_RegionalDivision_RoomLibrary_PrintAssetCard">打印勾选资产标识卡</el-button>
              <!-- 添加修改资产弹窗 -->
              <el-dialog width="1000px" v-loading="loading" :title="addAssetsForm.dialogAddElectricTitle" @close="dialogAddElectricClose" :visible.sync="addAssetsForm.dialogAddElectric" :close-on-click-modal="false" append-to-body>
                &nbsp;&nbsp;&nbsp;&nbsp;资产归属<el-input disabled :value="homeRow.hsAddCommunity"  clearable size="mini" style="width:300px"></el-input>
                <el-form ref="electric" :model="addAssetsForm.assets" label-width="75px" :rules="rules" inline >
                  <el-form-item label="资产所属" prop="saType">
                    <el-select v-model="addAssetsForm.assets.saType" size="mini" clearable style="width:120px">
                      <el-option label value></el-option>
                      <el-option label="公司" value="公司"></el-option>
                      <el-option label="租赁" value="租赁"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="使用情况" prop="saUse">
                    <el-select v-model="addAssetsForm.assets.saUse" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.saUseList" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="资产状态" prop="saStatus">
                    <el-select v-model="addAssetsForm.assets.saStatus" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.saStatusList" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="价格" prop="saPrice" label-width="50px">
                    <el-input v-model="addAssetsForm.assets.saPrice" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="资产类型" prop="saClassify">
                    <el-select @change="classifyChange" v-model="addAssetsForm.assets.saClassify" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="名称" prop="saName">
                    <el-select v-model="addAssetsForm.assets.saName" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.assetsType" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="品牌">
                    <el-input v-model="addAssetsForm.assets.saBrand" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="型号" label-width="50px">
                    <el-input v-model="addAssetsForm.assets.saModel" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item v-show="addAssetsForm.onlyFixAssets==1" label="数量">
                    <el-input v-model="addAssetsForm.numbers" type="tel" oninput="value=value.replace(/[^\d]/g,'')" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="供应商">
                    <el-input @click.native="selectAddressBelong('选择供应商')" v-model="addAssetsForm.assets.saSupplierName" readonly size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="addAssetsForm.assets.saRemarks" type="textarea" :rows="1" size="mini" style="width:310px" clearable></el-input>
                  </el-form-item>
                  <el-form-item v-show="addAssetsForm.onlyFixAssets==1">
                    <el-button size="mini" type="primary" @click="addAssets2">添加</el-button>
                    <el-button size="mini" type="danger" @click="clearAssets">清除</el-button>
                  </el-form-item>
                  <el-table v-show="addAssetsForm.onlyFixAssets==1" :data="addAssetsForm.assetsPARMS" style="width: 100%" size="mini" height="200px" max-height="200px">
                    <el-table-column prop="assetsBelong" label="地址/项目" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="saType" label="所属"></el-table-column>
                    <el-table-column prop="saClassify" label="类型"></el-table-column>
                    <el-table-column prop="saUse" label="使用"></el-table-column>
                    <el-table-column prop="saStatus" label="状态"></el-table-column>
                    <el-table-column prop="saName" label="名称"></el-table-column>
                    <el-table-column prop="saBrand" label="品牌"></el-table-column>
                    <el-table-column prop="saModel" label="型号"></el-table-column>
                    <el-table-column prop="saPrice" label="价值"></el-table-column>
                    <el-table-column prop="saRemarks" label="备注" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="saSupplierName" label="供应商"></el-table-column>
                    <el-table-column fixed="right" label="删除" width="80">
                      <template slot-scope="scope">
                        <el-button @click.native.prevent="deleteRow(scope.$index, addAssetsForm.assetsPARMS)" type="text" size="small"> 删除 </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="addAssetsForm.dialogAddElectric=false">取消</el-button>
                  <el-button size="mini" type="primary" v-show="addAssetsForm.onlyFixAssets==1" @click="postElectric">保存</el-button>
                  <el-button size="mini" type="primary" v-show="addAssetsForm.onlyFixAssets==2" @click="putElectric">保存</el-button>
                </div>
              </el-dialog>
            
              <el-dialog width="600px" v-loading="loading" @close="dialogAssMoveOut" title="迁移资产" :visible.sync="moveOutForm.dialogMoveOut" :close-on-click-modal="false" append-to-body>
                <el-form label-width="120px">
                  <el-form-item label="迁出地址">
                    <el-input v-model="moveOutForm.address" size="mini" readonly style="width:400px"></el-input>
                  </el-form-item>
                  <el-form-item label="迁入地址">
                    <el-input :value="roomAddress" @click.native="selectAddressBelong('选择地址')" size="mini" style="width:400px"></el-input>
                  </el-form-item> 
                  <el-form-item label="经手人">
                    <el-cascader @change="handIdChange" v-model="moveOutForm.handId" :options="userNameList" filterable clearable  :props="{ expandTrigger: 'hover' }" size="mini" style="width:400px"></el-cascader>
                  </el-form-item> 
                  <el-form-item label="迁移原因">
                    <el-input type="textarea" v-model="moveOutForm.moveReason" size="mini" style="width:400px"></el-input>
                  </el-form-item> 
                </el-form>
                <div slot="footer">
                  <el-button @click="moveOutForm.dialogMoveOut=false">取消</el-button>
                  <el-button @click="saveMoveOutAss" type="primary">保存</el-button>
                </div>

              </el-dialog>
            
              <el-dialog width="600px" style="height:600px" title="打印勾选资产标识卡" :visible.sync="printCodeForm.dialogprintCode" :close-on-click-modal="false" append-to-body>
                <div style="width:100%" id="code" ref="code">
                  <div v-for="(item,i) in printCodeForm.urlList" :key="i" style="text-align:center;margin-top:20px">
                    <p style="font-size:18px;line-height:8px">资产标识卡NO.{{item.number}}</p>
                    <div style="display:flex;justify-content:center;">
                      <div>
                        <vue-qr :text="item.url" :margin="0" colorDark="#333" colorLight="#fff" :size="120"></vue-qr>
                      </div>
                      <div style="width:180px;font-size:14px;text-align:left;line-height:8px;margin-left:5px;">
                        <p>名称 {{item.name}}</p>
                        <p>品牌 {{item.brand}}</p>
                        <p>型号 {{item.model}}</p>
                        <p>地址 {{item.address}}</p>
                        <p>状态 {{item.status}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div slot="footer">
                  <el-button @click="printCodeForm.dialogprintCode=false">取消</el-button>
                  <el-button v-print="'#code'" type="primary">打印</el-button>
                </div>
              </el-dialog>
          </el-row>
          <!-- 头部筛选框开始 -->
          <el-form inline label-width="70px">
            <el-form-item label="资产所属">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saType" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="公司" value="公司"></el-option>
                <el-option label="租赁" value="租赁"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用情况">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saUse" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="未使用" value="未使用"></el-option>
                <el-option label="使用中" value="使用中"></el-option>
                <el-option label="已报废" value="已报废"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产状态">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saStatus" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="正常" value="正常"></el-option>
                <el-option label="损坏" value="损坏"></el-option>
                <el-option label="丢失" value="丢失"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产编号">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saNumber" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="资产类型">
              <el-select @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saClassify" size="mini" clearable style="width:120px">
                <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产名称">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saName" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="品牌">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saBrand" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="型号">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saModel" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
          </el-form>
          <el-table @row-click="homeTableClick" @selection-change="homeTableSelect" :row-class-name="tableRowShowInput" @row-dblclick="homeTableDblclick" ref="homeTable" :cell-style="cellStyle" :data="assetForm.electricList" height="300px" fit show-header size="mini" highlight-current-row style="width: 100%" >
            <el-table-column align="center" type="selection" width="45px"></el-table-column>
            <el-table-column align="center" prop="saNumber" label="资产编号" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="address" label="地址" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="saType" label="所属"></el-table-column>
            <el-table-column align="center" prop="saClassify" label="类型"></el-table-column>
            <el-table-column align="center" prop="saName" label="名称"></el-table-column>
            <el-table-column align="center" prop="saBrand" label="品牌"></el-table-column>
            <el-table-column align="center" prop="saModel" label="型号"></el-table-column>
            <el-table-column align="center" prop="saPrice" label="价格"></el-table-column>
            <el-table-column align="center" prop="saDepreciationPrice" label="折旧价格"></el-table-column>
            <el-table-column align="center" prop="saStatus" label="状态"></el-table-column>
            <el-table-column align="center" prop="saUse" label="使用情况"></el-table-column>
            <el-table-column align="center" prop="saPhotosNum" label="图片"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="assetForm.limit"
            align="center"
            :current-page="assetForm.cursor"
            layout="prev, pager, next"
            @current-change="electricCurChange2"
            :total="assetForm.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog width="800px" title="资产详细信息" :visible.sync="assetForm.dialogElectric" :close-on-click-modal="false" append-to-body>
            <el-button size="mini" type="primary" @click="uploadPhoto">上传及查看图片</el-button>
            <el-form inline label-width="70px">
              <el-form-item label="资产归属">
                <el-input v-model="assetForm.homeRow.address" size="mini" readonly style="width:300px"></el-input>
              </el-form-item>
              <el-form-item label="资产所属">
                <el-input v-model="assetForm.homeRow.saType" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="使用情况">
                <el-input v-model="assetForm.homeRow.saUse" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="资产状态">
                <el-input v-model="assetForm.homeRow.saStatus" size="mini" readonly style="width:110px"></el-input>
              </el-form-item> 
              <el-form-item label="价格">
                <el-input v-model="assetForm.homeRow.saPrice" size="mini" readonly style="width:110px"></el-input>
              </el-form-item> 
              <el-form-item label="资产类型">
                <el-input v-model="assetForm.homeRow.saClassify" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="名称">
                <el-input v-model="assetForm.homeRow.saName" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="品牌">
                <el-input v-model="assetForm.homeRow.saBrand" size="mini" readonly style="width:300px"></el-input>
              </el-form-item> 
              <el-form-item label="型号">
                <el-input v-model="assetForm.homeRow.saModel" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="供应商">
                <el-input v-model="assetForm.homeRow.saSupplierName" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="资产编号">
                <el-input v-model="assetForm.homeRow.saNumber" size="mini" readonly style="width:180px"></el-input>
              </el-form-item>
              <el-form-item label="折旧价格">
                <el-input v-model="assetForm.homeRow.saDepreciationPrice" size="mini" readonly style="width:100px"></el-input>
              </el-form-item> 
              <el-form-item label="备注">
                <el-input type="textarea" v-model="assetForm.homeRow.saRemarks" size="mini" readonly style="width:660px"></el-input>
              </el-form-item> 
            </el-form>
            <el-table @row-dblclick="saFollowUpClick" :data="assFollowData.records" style="width: 100%" height="200px" size="mini">
              <el-table-column align="center" prop="jhfFollowTime" label="进展时间" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column align="center" prop="suStaffName" label="跟进人"></el-table-column>
              <el-table-column align="center" prop="jhfPaymentWay" label="跟进类型"></el-table-column>
              <el-table-column align="center" prop="jhfFollowRemark" :show-overflow-tooltip="true" min-width="180px" label="进展描述"></el-table-column>
            </el-table>
            <el-pagination 
              background
              :page-size="assFolLimit"
              align="center"
              :current-page="assFolCur"
              layout="prev, pager, next"
              @current-change="assCurChange"
              :total="assFollowData.total">
            </el-pagination>
            <el-dialog width="500px" title="进展详情" :visible.sync="assetForm.dialogFollowup" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tbody>
                  <tr>
                    <td >跟进时间</td>
                    <td colspan="3" style="color:blue;">{{assetForm.followupRow.jhfFollowTime}}</td>
                  </tr>
                  <tr>
                    <td >跟进人</td>
                    <td style="color:blue;">{{assetForm.followupRow.suStaffName}}</td>
                    <td >跟进类型</td>
                    <td style="color:blue">{{assetForm.followupRow.jhfPaymentWay}}</td>
                  </tr>
                  <tr>
                    <td>备注</td>
                    <td colspan="3" style="color:blue;">{{assetForm.followupRow.jhfFollowRemark}}</td>
                  </tr>
                </tbody>
              </table>   
            </el-dialog>
            <div style="margin-top:10px; text-align:center;">
              <el-button type="primary" @click="prvPrice" size="mini">上一条</el-button>
              <el-button size="mini" @click="assetForm.dialogElectric=false">关闭</el-button>
              <el-button type="primary" @click="nextPrice" size="mini">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="审批记录" name="approval" v-if="authe.Hotel_RegionalDivision_RoomLibrary_ApprovalRecord">
          <el-row>
            <el-button size="mini" type="primary" @click="addApproval" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AddApproval">添加审批</el-button>
            <el-button size="mini" type="primary" @click="seePhotoClick" v-if="authe.Hotel_RegionalDivision_RoomLibrary_ViewAttachments">查看附件</el-button>
              <el-dialog title="添加审批" :visible.sync="dialogAppro" @close="dialogApproClose" :close-on-click-modal="false" v-loading="loading" width="750px" append-to-body>
                <el-row>
                  <el-form size="mini" label-width="75px" inline style="margin-top:10px">
                    <el-form-item label="审批归属">
                      <el-input :value="homeRow.hsAddCommunity" disabled size="mini" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="审批人">
                      <el-cascader v-if="!isArea" v-model="eaEventHandler" :disabled="Boolean(addAppro.eaProcessId) && addAppro.eaProcessId!='自由流程'" :options="userNameList" filterable :props="{ expandTrigger: 'hover'}" size="mini" style="width:200px"></el-cascader>
                      <el-cascader v-if="isArea" v-model="eaEventHandler" :options="demoOpt" :props="{ expandTrigger: 'hover'}" size="mini" clearable filterable style="width:200px" ></el-cascader>
                    </el-form-item>
                    <el-form-item label="审批类型">
                      <el-select v-model="addAppro.eaProcessId" style="width:140px" @change="eaProcessChange">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="item in appList" :key="item.jeapId" :label="item.jeapType" :value="item.jeapId"></el-option>
                        <el-option label="自由流程" value="自由流程"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="审批编号">
                      <el-input v-model="addAppro.eaApprovalNumber" disabled style="width:140px"></el-input>
                    </el-form-item>
                    <el-form-item label="涉及金额">
                      <el-input v-model="addAppro.eaAmountInvolved" style="width:140px" type="tel" oninput="value=value.replace(/[^\d.-]/g,'')"></el-input>
                    </el-form-item>
                    <el-row v-show="addAppro.eaAmountInvolved">
                      <el-form-item label="账号名称">
                        <el-input v-model="addAppro.eaBankUsername" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="开户银行">
                        <el-input v-model="addAppro.eaBankName" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="银行账号">
                        <el-input v-model="addAppro.eaBankAccountNumber" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="所属支行">
                        <el-input v-model="addAppro.eaSubordinateBranch" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="账号描述">
                        <el-input v-model="addAppro.eaBankAccountDescription" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" @click="selAcc">选择账号</el-button>
                      </el-form-item>
                    </el-row>
                    <el-form-item label="短信提醒">
                      <el-checkbox v-model="msgChecked"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="优先处理">
                      <el-checkbox v-model="firstCheck"></el-checkbox>
                    </el-form-item>
                    <el-form-item>
                      <el-button size="mini" type="primary" @click="uploadOfAppro">附件</el-button>
                    </el-form-item>
                    <el-form-item label="审批内容">
                      <el-input type="textarea" :rows="3" v-model="addAppro.eaEventContent" style="width:590px"></el-input>
                    </el-form-item>
                  </el-form>
                </el-row>
                <div slot="footer">
                  <el-button size="mini" @click="dialogAppro=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveApproval">保存</el-button>
                </div>
              </el-dialog>
              <el-dialog title="银行账号" :visible.sync="selBankAccDia" width="800px" :close-on-click-modal="false" append-to-body>
                <el-form label-width="70px" size="mini" inline>
                  <el-form-item label="账号名称">
                    <el-input v-model="scAccName" @change="getBankAccData" size="small"></el-input>
                  </el-form-item>
                  <el-form-item label="开户银行">
                    <el-input v-model="scBank" @change="getBankAccData" size="small"></el-input>
                  </el-form-item>
                </el-form>
                <el-table :data="bankAccData.records" style="width: 100%" height="396px" @row-dblclick="dbSelAcc">
                  <el-table-column prop="csAccountName" label="账号名称"></el-table-column>
                  <el-table-column prop="csBank" label="开户银行"></el-table-column>
                  <el-table-column prop="csAccountUmber" label="银行账号"></el-table-column>
                  <el-table-column prop="csSubordinateBranch" label="所属支行"></el-table-column>
                  <el-table-column prop="csAccountDescription" label="账号描述"></el-table-column>
                </el-table>
                <el-pagination
                  background
                  :page-size="bankLim" 
                  align="center"
                  :current-page="bankCur"
                  layout="prev, pager, next"
                  @current-change="bankCurChange"
                  :total="bankAccData.total"
                  style="margin-top: 10px">
                </el-pagination>
              </el-dialog>
          </el-row>
          <el-table :data="approvalData.records" @row-dblclick="approvalDblClick" @row-click="approvalClick" :row-class-name="tableRowShowInput" ref="appro" style="width: 100%" height="396px" highlight-current-row size="mini">
            <el-table-column align="center" prop="eaEventState" label="审批状态"></el-table-column>
            <el-table-column align="center" prop="eaEventType" label="审批类型"></el-table-column>
            <el-table-column align="center" prop="eaReleaseTime" label="申请时间" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="eaEventContent" label="审批内容" min-width="140px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="eaAmountInvolved" label="涉及金额"></el-table-column>
            <el-table-column align="center" prop="eaImgNum" label="附件数量"></el-table-column>
            <el-table-column align="center" prop="eaCompletionTime" label="完成时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="totalPage" label="审批耗时"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="approvalParams.limit" 
            align="center"
            :current-page="approvalParams.cursor"
            layout="prev, pager, next"
            @current-change="approCurChange"
            :total="approvalData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog :title="`查看${approvalRow.eaEventType}审批单`" :visible.sync="dialogAppDbl" :close-on-click-modal="false" v-loading="loading" width="750px" append-to-body>
            <el-row>
              <el-col :span="12">归属地址：{{homeRow.hsAddCommunity}}</el-col>
              <el-col :span="12" style="text-align:right">审批编号：{{approvalRow.eaApprovalNumber}}</el-col>
            </el-row>
            <el-row>
              <table class="tableSp" style="margin:auto;width:700px">
                <tbody>
                  <tr>
                    <td >申请人</td>
                    <td style="color:blue;">{{approvalRow.handlerName}}</td>
                    <td >申请时间</td>
                    <td style="color:blue;">{{approvalRow.eaReleaseTime}}</td>
                  </tr>
                  <tr>
                    <td>申请内容</td>
                    <td colspan="3" style="color:blue;">{{approvalRow.eaEventContent}}</td>
                  </tr>
                  <tr>
                    <td>涉及金额</td>
                    <td colspan="3" style="color:blue;">￥{{approvalRow.eaAmountInvolved}}  (大写：{{this.numberChinese(approvalRow.eaAmountInvolved)}})</td>
                  </tr>
                  <tr v-show="approvalRow.eaBankName || approvalRow.eaBankUsername || approvalRow.eaBankAccountNumber">
                    <td >收款账户</td>
                    <td style="color:blue;">{{approvalRow.eaBankName}}</td>
                    <td style="color:blue">{{approvalRow.eaBankUsername}}</td>
                    <td style="color:blue">{{approvalRow.eaBankAccountNumber}}</td>
                  </tr>
                </tbody>
              </table>
              <el-button size="mini" type="primary" @click="seePhotoClick">附件</el-button>
            </el-row>
            <el-table :data="eaTreatmentOpinion" @row-dblclick="eaTreatmentDblclick" height="200px" highlight-current-row>
              <el-table-column align="center" prop="name" label="审批人"></el-table-column>
              <el-table-column align="center" prop="advise" label="审批意见"></el-table-column>
              <el-table-column align="center" prop="time" label="审批时间"></el-table-column>
            </el-table>
            <el-dialog width="500px" title="详细处理信息" :visible.sync="dialogAppFol" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tbody>
                  <tr>
                    <td >审批时间</td>
                    <td style="color:blue;">{{appFollowRow.time}}</td>
                    <td >审批人</td>
                    <td style="color:blue">{{appFollowRow.name}}</td>
                  </tr>
                  <tr>
                    <td >审批意见</td>
                    <td colspan="3" style="color:blue;">{{appFollowRow.advise}}</td>
                  </tr>
                </tbody>
              </table>   
            </el-dialog>
            <table class="tableSp" style="margin:auto;width:450px" v-if="approvalRow.eaWhetherGenerateRecord=='是'">
                <tbody>
                  <tr>
                    <td >操作人</td>
                    <td style="color:blue;">{{approvalRow.handlerName}}</td>
                    <td >付款金额</td>
                    <td style="color:blue;">￥{{approvalRow.eaAmountInvolved}}</td>
                    <td >财务流水号</td>
                    <td style="color:blue;">{{approvalRow.eaFinancialCoding}}</td>
                  </tr>
                  <tr>
                    <td>付款时间</td>
                    <td colspan="" style="color:blue;">{{approvalRow.jfCheckInTime}}</td>
                    <td>付款账户</td>
                    <td colspan="3" style="color:blue;">{{approvalRow.payInfo}}</td>
                  </tr>
                </tbody>
              </table>
            <div slot="footer" align="center">
              <el-button type="primary" @click="approPrv">上一条</el-button>
              <el-button type="primary" @click="approNext">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="任务记录" name="task" v-if="authe.Hotel_RegionalDivision_RoomLibrary_TaskLog">
          <el-row>
            <el-button size="mini" type="primary" @click="addTaskClick" v-if="authe.Hotel_RegionalDivision_RoomLibrary_AddTask">添加任务</el-button>
              <el-dialog title="添加任务" :visible.sync="dialogTask" @close="dialogTaskClose" :close-on-click-modal="false" v-loading="loading" width="550px" append-to-body>
                <el-row>
                  <el-form size="mini" label-width="75px" inline style="margin-top:10px">
                    <el-form-item label="负责人">
                      <el-cascader v-model="repRepairPeopleId" :options="userNameList" filterable clearable  :props="{ expandTrigger: 'hover' }" size="mini" style="width:170px"></el-cascader>
                    </el-form-item>
                    <el-form-item label="任务类型">
                      <el-select v-model="addTask.repTypeRp" size="mini" style="width: 170px">
                        <el-option v-for="item in companyVars.taskTypeList" :key="item.variable" :label="item.variable" :value="item.variable"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="客户姓名">
                      <el-input v-model="addTask.repContacts" style="width:170px"></el-input>
                    </el-form-item>
                    <el-form-item label="客户电话">
                      <el-input v-model="addTask.repContactsPhone" style="width:170px"></el-input>
                    </el-form-item>
                    <el-form-item label="期望时间">
                      <el-select v-model="addTask.repHopeTime" size="mini" style="width: 170px">
                        <el-option v-for="item in timeList" :key="item" :label="item" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="任务等级">
                      <el-select v-model.lazy="addTask.repGrade" size="mini" clearable style="width:170px">
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="uploadOfTask">附件</el-button>
                    </el-form-item>
                    <el-form-item label="短信提醒">
                      <el-checkbox v-model="addTask.sendMessage" true-label="1" false-label="2"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="任务描述">
                      <el-input type="textarea" :rows="3" v-model="addTask.repEventRp" style="width:420px"></el-input>
                    </el-form-item>
                  </el-form>
                </el-row>
                <div slot="footer">
                  <el-button size="mini" @click="dialogTask=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveTask">保存</el-button>
                </div>
              </el-dialog>
          </el-row>
          <el-table :data="taskData.records" @row-dblclick="taskDataDblclick" :row-class-name="tableRowShowInput" ref="task" style="width: 100%" height="396px" highlight-current-row size="mini">
            <el-table-column align="center" prop="repState" label="任务状态"></el-table-column>
            <el-table-column align="center" prop="repTypeRp" label="任务类型"></el-table-column>
            <el-table-column align="center" prop="repReportingTime" label="登记时间" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repEventRp" label="任务描述" min-width="140px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repToReceive" label="受理时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repImgNum" label="图片数量"></el-table-column>
            <el-table-column align="center" prop="repFinishTime" label="完成时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="timeConsuming" label="总耗时"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="taskLim" 
            align="center"
            :current-page="taskCur"
            layout="prev, pager, next"
            @current-change="taskCurChange"
            :total="taskData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog title="任务信息" :visible.sync="dialogTaskDbl" :close-on-click-modal="false" width="710px" append-to-body>
            <el-button size="mini" type="primary" @click="taskUpload">附件</el-button>
            <el-form inline size="mini" label-width="70px">
              <el-form-item label="库房名称">
                <el-input :value="homeRow.hsAddCommunity" disabled style="width:220px"></el-input>
              </el-form-item>
              <el-form-item label="任务类型">
                <el-input :value="taskRow.repTypeRp" disabled style="width:140px"></el-input>
                <el-input value="项目任务" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="任务状态">
                <el-input :value="taskRow.repState" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="负责人">
                <el-input :value="taskRow.repairman" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="领取时间">
                <el-input :value="taskRow.repToReceive" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="客户姓名">
                <el-input :value="taskRow.repContacts" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="客户电话">
                <el-input :value="taskRow.repContactsPhone" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="期望时间">
                <el-input :value="taskRow.repHopeTime" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="登记人">
                <el-input :value="taskRow.username" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="登记时间">
                <el-input :value="taskRow.repReportingTime" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="任务描述">
                <el-input :value="taskRow.repEventRp" type="textarea" disabled style="width:580px"></el-input>
              </el-form-item>
            </el-form>
            <el-table :data="taskFollowData" @row-dblclick="taskFollDblclick" height="200px" highlight-current-row size="mini">
              <el-table-column align="center" prop="proTime" label="进展时间"></el-table-column>
              <el-table-column align="center" prop="suStaffName" label="负责人"></el-table-column>
              <el-table-column align="center" prop="proState" label="进展状态"></el-table-column>
              <el-table-column align="center" prop="proRemark" label="备注"></el-table-column>
            </el-table>
            <el-dialog width="500px" title="进展详情" :visible.sync="dialogTaskFol" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tbody>
                  <tr>
                    <td >进展时间</td>
                    <td colspan="3" style="color:blue;">{{taskFollowRow.proTime}}</td>
                  </tr>
                  <tr>
                    <td >处理人</td>
                    <td style="color:blue;">{{taskFollowRow.suStaffName}}</td>
                    <td >进展状态</td>
                    <td style="color:blue">{{taskFollowRow.proState}}</td>
                  </tr>
                  <tr>
                    <td >备注</td>
                    <td colspan="3" style="color:blue;">{{taskFollowRow.proRemark}}</td>
                  </tr>
                </tbody>
              </table>   
            </el-dialog>
            <div slot="footer" align="center">
              <el-button type="primary" @click="taskPrv">上一条</el-button>
              <el-button type="primary" @click="taskNext">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="收支记录" name="budget" v-if="authe.Hotel_RegionalDivision_RoomLibrary_IncomeExpenditureRecords">
          <el-form size="mini" inline>
            <el-form-item label="记账日期">
              <el-date-picker v-model="commonQuery.startTime" @change="getRecords(1)" type="date" size="mini" placeholder="选择日期" style="width:150px" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>至
              <el-date-picker v-model="commonQuery.endTime" @change="getRecords(1)" type="date" size="mini" placeholder="选择日期" style="width:150px" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </el-form>
          <el-table @row-dblclick="budgetDblclick" :data="budgetData.records" fit highlight-current-row style="width: 100%" height="396px" size="mini">
            <el-table-column prop="jfAuditState" label="财务状态" align="center"></el-table-column>
            <el-table-column prop="jfClosedWay" label="账户类型" min-width="80px" align="center" ></el-table-column>
            <el-table-column prop="jfNatureOfThe" label="收支性质" min-width="50px" align="center"></el-table-column>
            <el-table-column prop="jfAccountingSpecies" label="收支种类" min-width="80px" show-overflow-tooltip align="center" ></el-table-column>
            <el-table-column prop="jfSumMoney" label="收支金额" min-width="80px" align="center"></el-table-column>
            <el-table-column prop="handlersName" label="经手人" min-width="70px" align="center" ></el-table-column>
            <el-table-column prop="jfCheckInTime" label="登记日期" min-width="100px" show-overflow-tooltip align="center" ></el-table-column>
            <el-table-column prop="cashierPeopleName" label="记账人" width="80px" align="center"></el-table-column>
            <el-table-column prop="reviewerName" label="审核人" width="80px" align="center"></el-table-column>
            <el-table-column prop="reviewOneName" label="复核人" width="80px" align="center"></el-table-column>
            <el-table-column prop="jfStrikeABalanceStatus" label="冲账状态" width="80px" align="center"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="commonQuery.limit" 
            align="center"
            :current-page="commonQuery.cursor"
            layout="prev, pager, next"
            @current-change="budgetCurChange"
            :total="budgetData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog width="900px" title="收支详情" :visible.sync="dialogBudget" :close-on-click-modal="false" append-to-body>
            <table class="tableSp" style="margin:auto;width:850px">
              <tbody>
                <tr>
                  <td >楼盘名称</td>
                  <td colspan="5" style="color:blue;">库房--{{homeRow.addDoorplateno}}</td>
                </tr>
                <tr>
                  <td >财务状态</td>
                  <td style="color:blue;">{{budgetRow.jfAuditState}}</td>
                  <td >冲账状态</td>
                  <td style="color:blue">{{budgetRow.jfStrikeABalanceStatus}}</td>
                  <td >经手人</td>
                  <td style="color:blue">{{budgetRow.handlersName}}</td>
                </tr>
                <tr>
                  <td >归属类型</td>
                  <td style="color:blue;">{{budgetRow.jfTheOwnershipType}}</td>
                  <td >归属名称</td>
                  <td style="color:blue">{{budgetRow.jfBelongingToTheName}}</td>
                  <td >记账日期</td>
                  <td style="color:blue">{{budgetRow.jfCheckInTime}}</td>
                </tr>
                <tr>
                  <td >账户类型</td>
                  <td style="color:blue;" colspan="2">{{budgetRow.jfClosedWay}}</td>
                  <td >账户名称</td>
                  <td style="color:blue" colspan="2">{{budgetRow.faUserName}}</td>
                </tr>
                <tr>
                  <td >账户号码</td>
                  <td style="color:blue;" colspan="2">{{budgetRow.faAccount}}</td>
                  <td >账户归属</td>
                  <td style="color:blue" colspan="2">{{budgetRow.faBelonging}}</td>
                </tr>
                <tr>
                  <td >收支性质</td>
                  <td style="color:blue;">{{budgetRow.jfNatureOfThe}}</td>
                  <td >收支类别</td>
                  <td style="color:blue">{{budgetRow.jfBigType}}</td>
                  <td >收支种类</td>
                  <td style="color:blue">{{budgetRow.jfAccountingSpecies}}</td>
                </tr>
                <tr>
                  <td >收支金额</td>
                  <td style="color:blue;">{{budgetRow.jfSumMoney}}</td>
                  <td >归属周期</td>
                  <td style="color:blue" colspan="3">{{budgetRow.jfStartCycle}}到{{budgetRow.jfEndCycle}}</td>
                </tr>
                <tr>
                  <td >票据编号</td>
                  <td style="color:blue;">{{budgetRow.jfTicketNumber}}</td>
                  <td >流水号</td>
                  <td style="color:blue">{{budgetRow.jfFinancialCoding}}</td>
                  <td >凭证号</td>
                  <td style="color:blue">{{budgetRow.jfCertificateNumber}}</td>
                </tr>
                <tr>
                  <td>收支原因</td>
                  <td colspan="5" style="color:blue;">{{budgetRow.jfFinanNote}}</td>
                </tr>
                <tr>
                  <td>操作记录</td>
                  <td colspan="5" style="color:blue;">{{budgetRow.jfOperationRecords}}</td>
                </tr>
                <tr>
                  <td >记账人</td>
                  <td style="color:blue;">{{budgetRow.cashierPeopleName}}</td>
                  <td >审核人</td>
                  <td style="color:blue">{{budgetRow.reviewerName}}</td>
                  <td >复核人</td>
                  <td style="color:blue">{{budgetRow.reviewOneName}}</td>
                </tr>
              </tbody>
            </table>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 房间选择 -->
    <select-address
      v-if="costRelationVisible"
      :isDialogShow="costRelationVisible"
      :isTitle="costRelationTitle"
      @close="selectRoomDblclick"
    >
    </select-address>
    <el-dialog width="680px" title="查看图片" v-loading="loading" :visible.sync="dialogSeeImgPath" :close-on-click-modal="false" append-to-body>
      <div style="width:100%;height:400px;overflow-y:auto">
        <el-image v-for="(item, index) in photoList" :key="index" style="width: 150px; height: 150px;border-radius:5px;margin:5px 5px" :src="item.url" :preview-src-list="photoList"></el-image>
      </div>
    </el-dialog>
    <!-- 图片上传组件 -->
    <common-upload ref="upload" @listenEvent="dialogImgPathClose" :dialog="dialogImgPath" :save="needSavePhoto" :photoid="photoTableId" :photorandom="photoRandom" :phototype="photoTableType"></common-upload>

  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex'
  //  引入上传图片组件
  import commonUpload from '../../components/common-upload'
  import selectAddress from '../../components/selectAddress/selectAddress'
  export default {
     components: {
      'common-upload':commonUpload,
      'select-address':selectAddress
    },
    name:'roomLibrary',
    data(){
      return{
        rules:{
          supType:[{ required: true, message: '耗材类型不能为空', trigger: 'change'}],
          supName:[{ required: true, message: '名称不能为空', trigger: 'blur'}],
          supPrice:[{ required: true, message: '价格不能为空', trigger: 'blur'}],
          supNum:[{ required: true, message: '数量不能为空', trigger: 'blur'}],
          saType:[{ required: true, message: '资产所属不能为空', trigger: 'change'}],
          saUse:[{ required: true, message: '使用情况不能为空', trigger: 'change'}],
          saStatus:[{ required: true, message: '资产状态不能为空', trigger: 'change'}],
          saClassify:[{ required: true, message: '资产类型不能为空', trigger: 'change'}],
          saName:[{ required: true, message: '名称不能为空', trigger: 'change'}],
          saPrice:[{ required: true, message: '价格不能为空', trigger: 'blur'}],
        },
        loading:false,
        tableHeight:window.innerHeight - 240,
        dialogWarehouse:false,
        wareTitle:'',
        homeParms:{
          limit:10,
          cursor:1,
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity:'库房',
        },
        homeData:{},//首页分页表格数据
        homeRow:{},//首页表格双击行数据
        activeName:'',
        dialogInfo:false,//首页表格双击弹框
        addData:{//库房添加修改数据
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity: '库房',
        },
    //选择房间数据
        costRelationTitle:'',
        costRelationVisible:false,
        selectedHouse:{},
        roomAddress:'',
        roomHsId:'',
    //双击弹框内数据
      //耗材数据
        suppliesParams:{
          cursor:1,
          limit:10,
          houseCoding:'',
          supBrand:'',
          supModel:'',
          supName:'',
          supType:''
        },
        suppliesData:{},
        dialogAddSup:false,
        supTitle:'',
        addSupData:{
          supType:'',
          supName:'',
          supBrand:'',
          supModel:'',
          supPrice:'',
          supNum:'',
          supRemark:'',
        },
        suppliesRow:{},
        // 迁出耗材
        dialogMoveOut:false,
        moveNum:'',
        // 使用耗材
        dialogUse:false,
        useNum:'',
        useRemark:'',
        //增减耗材
        dialogSubSupplies:false,
        subOrAddNum:'',
        subOrAddRemark:'',
        //双击耗材
        dialogDblclick:false,
        subDbCursor:1,
        subDbLimit:5,
        supFollow:{},
        supFolRow:'',
        dialogSupFol:false,

      //资产数据
        assetForm: {
          topShow: false,
          dialogElectric: false,
          limit: 10,
          cursor: 1,
          total: 0,
          pages: 0,
          electricList: [],
          dialogFollowup:false,
          followupRow:'',
          homeRow:'',
          homeSel:[],
          params: {
            saType: '',//资产所属
            saUse: '',//使用情况
            saStatus: '',//资产状态
            saNumber: '',//资产编号
            saClassify: '',//资产类型
            saName: '',//资产名称
            saBrand: '',//品牌
            saModel: '',//型号
          }
        },
        assFolLimit :5,
        assFolCur:1,
        assFollowData:{},
        addAssetsForm:{
          dialogAddElectric:false,
          assets: {
            saType: '', //公司
            saClassify: '', //办公设备
            saUse: '', //未使用
            saStatus: '', //正常
            saName: '', //打印机
            saBrand: '', //A牌
            saModel: '', //型号
            saPrice: '', //价格
            saRemarks: '', //00备注00
            assetsBelong:'',//资产所属
            saSupplierName:'',//供应商名称
            saSupplier:'',//供应商Id
          },
          dialogAddElectricTitle:'',
          onlyFixAssets:'1',
          assetsType:[],
          saTypeList:['','公司','业主','租赁'],
          saUseList:['','未使用','使用中','已报废'],
          saStatusList:['','正常','损坏','丢失','注销'],
          limit: 10,
          cursor: 1,
          total: 0,
          pages: 0,
          limit2:10,
          cursor2:1,
          total2:0,
          pages2:0,
          dialogHouseList:false,
          number: '',
          assetsPARMS:[],
        },
        moveOutForm:{
          address:'',
          moveReason:'',
          handId:'',
          saAgent:'',
          dialogMoveOut:false
        },
        printCodeForm:{
          dialogprintCode:false,
          urlList:[],
          selList:[],
        },
      //审批
        approvalParams:{
          limit:10,
          cursor:1,
          eaStoreId:''
        },
        dialogAppro:false,
        appList:[],
        approvalData:{},
        eaEventHandler:[],
        addAppro:{
          eaAmountInvolved: '',
          eaEventType: '',
          eaProcessId:'',
          eaApprovalNumber: '',
          eaEventContent: '',
          eaBankAccountDescription: '',//账号描述
          eaBankAccountNumber: '',
          eaBankName: '',
          eaBankUsername: '',
          eaSubordinateBranch:''
        },
        demoOpt : [],
        isArea : false,
        msgChecked:false, 
        firstCheck:false,
        scBank:'', 
        scAccName:'',
        bankCur:1,
        bankLim:10,
        bankAccData:{},
        selBankAccDia:false,
        approvalRow:{},
        eaTreatmentOpinion:[],
        dialogAppFol:false, 
        appFollowRow:{},
        dialogAppDbl:false,
        dialogSeeImgPath:false, 
        photoList:[],
      //任务
        dialogTask:false,
        taskLim:10,
        taskCur:1,
        taskData:{},
        taskRow:{},
        repRepairPeopleId:'',
        addTask:{
          repEventRp:'',
          repContactsPhone:'',
          repContacts:'',
          repHopeTime:'尽快',
          sendMessage:'1',
          repTaskTime:'',
          repGrade:'1'
        },
        dialogTaskFol:false, 
        taskFollowRow:{},
        taskFollowData:[],
        timeList:[ '尽快', '今天', '明天', '后天', '本周末', '电话联系' ],
        dialogTaskDbl:false,
      //收支
        commonQuery: {//首页数据
          limit: 10, // 每页条数
          cursor: 1, // 当前页数
          hsId:'',
          parameter: 1, // 登记（1）/记账（2）日期
          sort: 2, // 正序（1）/ 倒叙（2）
          startTime:'', // 筛选开始时间
          endTime:''// 筛选结束时间
        },
        budgetData:{},
        budgetRow:{},
        dialogBudget:false,
      //上传图片
        dialogImgPath:false,
        needSavePhoto:false,
        photoTableId:'',
        photoTableType:'',
        photoRandom:'',
        textkey:1,
      }
    },
    computed: {
     ...mapState(['userName','userNameList','cityList','companyVars','currentUser','authe','pageAuth'])
    },
    created(){
      if (this.pageAuth['/roomLibrary']) {
        this.getHomeData()
        this.getSetApprData()
      } else {
        this.$router.replace('/notfind')
      }
    },
    watch:{
      activeName(val){
        if(val=='public'){
          
        }else if(val=='consumables'){
          this.getSupplies(1)
        }else if(val=='asset'){
          this.getAllElectric(1)
        }else if(val=='approval'){
          this.getApprovalPapers(1)
        }else if(val=='task'){
          this.getOddjobs(1)
        }else if(val=='budget'){
          this.getRecords(1)
        }
        this.assetForm.homeRow = ''
        this.approvalRow = ''
      },
    },
    methods:{
    //通用
      tableRowShowInput({row,rowIndex}){//获取表格当前页的数据，并添加索引值
        row.index=rowIndex
      }, 
      numberChinese(str) {//金额转中文
        let num = parseFloat(str);
        let strOutput = '',
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += '00';
        let intPos = num.indexOf('.');  
        if (intPos >= 0){
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (let i=0; i < num.length; i++){
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i,1),1) + strUnit.substr(i,1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, '零元')
      },
      taskCostTime(t1,t2){//计算耗时时间
        let costTime = '';
        t2 = !t2 ? moment().format('YYYY-MM-DD HH:mm:ss') : t2;
        console.log(t2)
        t1 = new Date(t1);
        t2 = new Date(t2);
        t1 = t1.getTime();
        t2 = t2.getTime();
        console.log(t2)
        let subTime = parseInt((t2-t1)/1000/60);
        if(subTime<60){
          costTime = subTime+'分';
          return costTime;
        }
        
        if((subTime/60)>=1 && (subTime/60/24)<1){
          if(subTime%60>0){
            costTime = (subTime/60).toString().split('.')[0]+'小时'+(subTime%60)+'分';
          }else{
            costTime = (subTime/60)+'小时';
          }
          return costTime;
        }
        if((subTime/60/24)>=1){
          if(parseInt(subTime/60)%24>=1){
            costTime = (subTime/(60*24)).toString().split('.')[0]+'天'+(parseInt(subTime/60)%24)+'小时';
          }else{
            costTime = parseInt(subTime/(60*24))+'天';
          }
          return costTime;
        }
      },
      taskTime(type){//任务时间格式
        let time=''
        if(type=='尽快'|| type=='电话联系'|| type=='今天'){
          time=moment().format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='明天'){
          time=moment().add(1,'days').format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='后天'){
          time=moment().add(2,'days').format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='本周末'){
          time=moment().day(0).format('YYYY-MM-DD HH:mm:ss'); // 这个星期日 (0)
        }
        return time
      },
      getSetApprData(){//获取审批流程,添加审批需要
        this.$axios({
          url: '/common1/examineApprovalProcess',
          method: 'get',
          params:{
            jeapState:1
          }
        }).then(res => {
          this.appList=res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      dialogImgPathClose(){//上传图片弹窗关闭
        this.dialogImgPath=false
      },
    //首页
      getHomeData(t){
        if(t==1){
          this.homeParms.cursor=1
        }
        this.$axios({
          method:'get',
          url:'/common1/house4store',
          params:this.homeParms
        }).then(res=>{
          this.homeData=res.data.result
          // this.$store.commit('hsAddCommunity','')
        }).catch(err => {
          this.isError(err,this)
        })
      },
      meterCurChange(val) {//分页
        this.homeParms.cursor = val;
        this.getHomeData();
      },
      meterSizeChange(val) {//分页
        this.homeParms.limit = val;
        this.getHomeData(1);
      },
      addWarehouse(){//添加库房按钮
        this.wareTitle='添加库房'
        this.dialogWarehouse=true
      },
      homeDataClick(row){//首页表格单击
        this.homeRow=row
      },
      updateWarehouse(){//修改库房按钮
        if(!this.homeRow.hsId){
          this.$message.warning('请选择一项')
          return
        }
        for(let i in this.addData){
          for(let k in this.homeRow){
            if(i==k){
              this.addData[i]=this.homeRow[k]
            }
          }
        }
        this.wareTitle='库房修改'
        this.dialogWarehouse=true
      },
      saveRoom(){//添加修改库房
        if(!this.addData.hsAddCommunity){
          this.$message.warning('库房名称不能为空')
          return
        }
        if(this.wareTitle=='添加库房'){//添加公区
          this.loading=true
          this.$axios({
            method:'post',
            url:'/common1/house4store/createPublicHs',
            data:this.addData
          }).then(res=>{
            this.dialogWarehouse=false
            this.loading=false
            this.$message.success('添加成功')
            this.homeRow={}
            this.getHomeData()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }else if(this.wareTitle=='库房修改'){//修改公区
          this.loading=true
          this.$axios({
            method:'put',
            url:`/common1/house4store/updatePublicHs/${this.homeRow.hsId}`,
            data:this.addData
          }).then(res=>{
            this.dialogWarehouse=false
            this.loading=false
            this.$message.success('修改成功')
            this.homeRow={}
            this.getHomeData()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }
      },
      dialogWarehouseClose(){//添加库房弹窗关闭
        this.addData={//库房添加修改数据
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity: '库房',
        }
        
      },
    //公共选择房间
      selectAddressBelong(str){//选择房间
        this.costRelationTitle = str
        this.costRelationVisible = true
      },
      selectRoomDblclick(row) {//双击一行房源
        if (row) {
          if (this.costRelationTitle == '选择地址' || this.costRelationTitle == '选择库房') {
            this.roomAddress = row.address
            this.roomHsId = row.hsId
          //   this.addAssetsForm.assets.assetsBelong = row.address;
          //   this.addAssetsForm.moveHsId = row.hsId;
          } else if (this.costRelationTitle == '选择供应商') {
            this.addAssetsForm.assets.saSupplierName = row.keyAdministrator
            this.addAssetsForm.assets.saSupplier = row.houseCoding
          }
        }
        this.selectedHouse = row
        this.costRelationVisible = false;
      },
    //双击弹框
      homeDataDblclick(row){//首页表格双击
        this.homeRow=row
        console.log(row)
        this.activeName='public'
        this.dialogInfo=true
      },
    //耗材
      getSupplies(t){//分页查询耗材
        if(t==1){
          this.suppliesParams.cursor=1
        }
        this.suppliesParams.hsId=this.homeRow.hsId
        this.suppliesData={}
        this.$axios({
          method:'get',
          url:'/common1/supplies',
          params:this.suppliesParams
        }).then(res=>{
          this.suppliesData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      suppCurChange(cursor){
        this.suppliesParams.cursor=cursor
        this.getSupplies()
      },
      addSupplies(){//添加耗材
        this.supTitle='添加耗材'
        this.dialogAddSup=true
      },
      suppliesDataClick(row){//耗材表格单击
        this.suppliesRow=row
      },
      updateSupplies(){//修改耗材
        if(!this.suppliesRow.supId){
          this.$message.warning('请选择一条耗材')
          return
        }
        for(let i in this.suppliesRow){
          for(let k in this.addSupData){
            if(i==k){
              this.addSupData[k]=this.suppliesRow[i]
            }
          }
        }
        this.supTitle='修改耗材'
        this.dialogAddSup=true
      },
      saveSupplies(){//添加修改耗材保存 按钮
        if(!this.submitForm(['addSup'],this)){
            return
          }
        if(this.supTitle=='添加耗材'){
          this.loading=true
          this.addSupData.houseCoding=this.homeRow.hsId
          this.$axios({
            method:'post',
            url:'/common1/supplies',
            data:this.addSupData
          }).then(res=>{
            this.dialogAddSup=false
            this.loading=false
            this.$message.success('添加成功')
            this.suppliesRow={}
            this.getSupplies()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }else if(this.supTitle=='修改耗材'){
          this.loading=true
          this.$axios({
            method:'put',
            url:`/common1/supplies/${this.suppliesRow.supId}`,
            data:this.addSupData
          }).then(res=>{
            this.dialogAddSup=false
            this.loading=false
            this.$message.success('修改成功')
            this.suppliesRow={}
            this.getSupplies()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }
      },
      dialogAddSupClose(){//添加修改耗材弹窗关闭
        this.addSupData={
          supType:'',
          supName:'',
          supBrand:'',
          supModel:'',
          supPrice:'',
          supNum:'',
          supRemark:'',
        }
      },
      moveSupplies(){//迁移耗材
        if(!this.suppliesRow.supId){
          this.$message.warning('请选择一条耗材')
          return
        }
        this.dialogMoveOut=true
      },
      saveMoveOut(){//迁去耗材保存
        if(!this.selectedHouse.address){
          this.$message.warning('请选择需要迁入的房间')
          return
        }
        if(Number(this.moveNum)>Number(this.suppliesRow.supNum) || !this.moveNum){
          this.$message.warning('迁出数量不能大于总数量')
          return
        }
        if(this.selectedHouse.houseCoding==this.suppliesRow.houseCoding){
          this.$message.warning('无需迁移')
          return
        }
        this.loading=true
        this.$axios({
          method:'put',
          url:`/common1/supplies/${this.suppliesRow.supId}/move`,
          data:{
            supMoveToHouseId:this.selectedHouse.houseCoding,
            supMoveFrom: this.suppliesRow.hsAddCommunity,
            supMoveTo: this.selectedHouse.address,
            supNum: this.moveNum,
          }
        }).then(res=>{
          this.dialogMoveOut=false
          this.loading=false
          this.$message.success('迁出成功')
          this.suppliesRow={}
          
          this.getSupplies()
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      },
      dialogSupMoveOut(){//迁出耗材弹窗关闭
        this.selectedHouse={}
        this.moveNum=''
        this.roomAddress = ''
        this.roomHsId = ''
      },
      useSupplies(){//使用耗材按钮
        if(!this.suppliesRow.supId){
          this.$message.warning('请选择一条耗材')
          return
        }
        this.dialogUse=true
      },
      saveUse(){//使用耗材保存
        if(!this.selectedHouse.address){
          this.$message.warning('请选择使用耗材的房间')
          return
        }
        if(Number(this.useNum)>Number(this.suppliesRow.supNum) || !this.useNum){
          this.$message.warning('使用数量错误')
          return
        }
        if(!this.useRemark){
          this.$message.warning('请填写使用原因')
          return
        }
        this.loading=true
        this.$axios({
          method:'put',
          url:`/common1/supplies/${this.suppliesRow.supId}/use`,
          data:{
            useAddress: this.selectedHouse.address,
            supNum: this.useNum,
            supRemark: this.useRemark,
            repairFlag : 2 
          }
        }).then(res=>{
          this.dialogUse=false
          this.loading=false
          this.$message.success('使用成功')
          this.suppliesRow={}
          this.getSupplies()
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      },
      dialogUseClose(){//s使用耗材弹窗关闭
        this.selectedHouse={}
        this.useNum=''
        this.useRemark=''
      },
      subSupplies(){//增减耗材按钮
        if(!this.suppliesRow.supId){
          this.$message.warning('请选择一条耗材')
          return
        }
        this.dialogSubSupplies=true
      },
      saveSubSupplies(){//增减耗材
        if(Math.abs(this.subOrAddNum)>Number(this.suppliesRow.supNum) || !this.subOrAddNum){
          this.$message.warning('减的数量错误')
          return
        }
        this.loading=true
        this.$axios({
          method:'put',
          url:`/common1/supplies/${this.suppliesRow.supId}/purchase`,
          data:{
            supNum: this.subOrAddNum,
            supRemark: this.subOrAddRemark,
          }
        }).then(res=>{
          this.dialogSubSupplies=false
          this.loading=false
          this.$message.success('保存成功')
          this.suppliesRow={}
          this.getSupplies()
        }).catch(err => {
          this.loading=false
          this.isError(err,this)
        })
      },
      dialogSubSuppliesClose(){//增减耗材弹窗关闭
        this.subOrAddNum=''
        this.subOrAddRemark=''
      },
      suppliesDblclick(row){//耗材表格双击
        this.suppliesRow=row
        this.subDbCursor=1
        this.getFollowupOfSup()
        this.dialogDblclick=true
        console.log(row)
      },
      getFollowupOfSup(){//获取耗材跟进
        this.supFollow={}
        this.$axios({
          method:'get',
          url:'/apartment/eventFollowupRecords',
          params:{
            limit:this.subDbLimit,
            cursor:this.subDbCursor,
            jhfTableType:'耗材',
            jhfTableId:this.suppliesRow.supId
          }
        }).then(res=>{
          this.supFollow=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      supFollCurChange(cur){//耗材分页跟进
        this.subDbCursor=cur
        this.getFollowupOfSup()
      },
      supFolDblclick(row){//双击耗材跟进
        this.supFolRow=row
        this.dialogSupFol=true
      },
      supPrv(){//上一条
        let i=this.suppliesRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.suppliesRow=this.suppliesData.records[i]
        this.$refs.supTable.setCurrentRow(this.suppliesData.records[i])
        this.suppliesDblclick(this.suppliesRow)
      }, 
      supNext(){//下一条
        let i=this.suppliesRow.index+1
        if(i==this.suppliesData.records.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.suppliesRow=this.suppliesData.records[i]
        this.$refs.supTable.setCurrentRow(this.suppliesData.records[i])
        this.suppliesDblclick(this.suppliesRow)
      },
      supPhoto(){//耗材上传图片
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.suppliesRow.supId
        this.photoTableType='耗材'
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
    //资产
      homeTableClick(row){//资产表格点击
        this.assetForm.homeRow=row
        console.log(this.assetForm.homeRow)
      },
      homeTableSelect(sel){//资产表格勾选
        this.assetForm.homeSel=[]
        this.printCodeForm.selList=[]
        if(sel.length>0){
          sel.forEach(item=>{
            this.assetForm.homeSel.push(item.saId)
          })
          this.printCodeForm.selList=sel
        }
      },
      cellStyle(row,column){//修改表格列的颜色
        if(row.column.label == '状态'){
          if(row.row.saUse == '使用中'){
            return 'color:green'
          }else if(row.row.saUse == '未使用'){
            return 'color:blue'
          }else if(row.row.saUse == '已报废'){
            return 'color:gray'
          }
        }
      },
      getAllElectric(t) {//分页查询资产
        this.assetForm.params.limit = this.assetForm.limit;
        this.assetForm.params.cursor = this.assetForm.cursor;
        if(t==1){
          this.assetForm.params.cursor = 1
        }
        this.assetForm.params.saHouseStoreId=this.homeRow.hsId
        this.$axios({
          method: 'get',
          url: '/common1/companyAssets',
          params: this.assetForm.params
        }).then(res => {
          res.data.result.records.forEach(item => {
            item.addCommunity = item.addCommunity ? item.addCommunity : ''
            item.roomType = item.roomType ? item.roomType : ''
            item.addDoorplateno = item.addDoorplateno ? item.addDoorplateno : ''
            item.address = `${item.addCommunity}\u3000${item.roomType}\u3000${item.addDoorplateno}`
            item.address = item.addCity == '公区' || item.addCity == '办公室' || item.addCity == '库房' ? item.addCommunity : item.address
          });
          this.assetForm.electricList = res.data.result.records;
          this.assetForm.total = res.data.result.total;
          console.log(this.assetForm.electricList)
        }).catch(err => {
          this.isError(err,this)
        });
      },
      electricCurChange2(val) {//资产分页
        this.assetForm.cursor = val;
        this.getAllElectric();
      },
      addAssetsClick(){//添加资产按钮
        this.addAssetsForm.dialogAddElectricTitle='添加资产'
        this.addAssetsForm.onlyFixAssets='1'
        this.addAssetsForm.dialogAddElectric=true
      },
      fixAssetsClick(){//修改资产按钮
        if(!this.assetForm.homeRow){
          this.$message.warning('请选择一个资产')
          return
        }
        for(let i in this.assetForm.homeRow){
          for(let k in this.addAssetsForm.assets){
            if(i==k){
              this.addAssetsForm.assets[k]=this.assetForm.homeRow[i]
            }
          }
          this.addAssetsForm.assets.assetsBelong=this.homeRow.address
          this.addAssetsForm.assets.saSupplierName=this.assetForm.homeRow.saSupplierName
        }
        this.classifyChange()
        this.addAssetsForm.dialogAddElectricTitle='修改资产'
        this.addAssetsForm.dialogAddElectric=true
        this.addAssetsForm.onlyFixAssets='2'
      },
      putElectric(){//修改保存资产按钮
        if (this.submitForm(['electric'], this)) {
          this.loading=true
          this.$axios({
            method:'put',
            url:`/common1/companyAssets/${this.assetForm.homeRow.saId}`,
            data:this.addAssetsForm.assets
          }).then(res=>{
            this.addAssetsForm.dialogAddElectric = false
            this.loading=false
            this.$message.success('修改成功')
            this.getAllElectric()
            console.log(res)
          }).catch(err => {
            this.loading = false;
            this.isError(err,this)
          });
        }
      },
      addAssets2() {//添加资产添加按钮
        if (this.submitForm(['electric'], this)) {
          if (this.addAssetsForm.numbers > 0) {
            for (let i = 0; i < Number(this.addAssetsForm.numbers); i++) {
              let obj = {
                saType: this.addAssetsForm.assets.saType,//公司
                saClassify: this.addAssetsForm.assets.saClassify,//办公设备
                saUse: this.addAssetsForm.assets.saUse,//未使用
                saStatus: this.addAssetsForm.assets.saStatus,//正常
                saName: this.addAssetsForm.assets.saName,//打印机
                saBrand: this.addAssetsForm.assets.saBrand,//A牌
                saModel: this.addAssetsForm.assets.saModel,//型号
                saPrice: this.addAssetsForm.assets.saPrice,//价格
                saRemarks: this.addAssetsForm.assets.saRemarks,//00备注00
                assetsBelong:this.homeRow.hsAddCommunity,//资产所属
                saSupplierName:this.addAssetsForm.assets.saSupplierName,//供应商
                saSupplier:this.addAssetsForm.assets.saSupplier,//供应商Id
              }
              this.addAssetsForm.assetsPARMS.push(obj);
            }
            console.log(this.addAssetsForm.assetsPARMS)
          } else {
            this.$message.warning('请填写数量');
          }
        } else {
          this.$message.warning('有必填项未填');
        }
      },
      postElectric() {//添加资产保存按钮
        if (this.addAssetsForm.assetsPARMS.length == 0) {
          this.$message.warning('请添加资产');
          return;
        }
        let obj = {
          saHouseStoreId: this.homeRow.hsId,
          assetsPARMS: this.addAssetsForm.assetsPARMS
        };
        console.log(this.addAssetsForm.assetsPARMS)
        this.loading = true;
        this.$axios({
          method: 'post',
          url: '/common1/companyAssets/saveOrUpdateBatch',
          data: obj
        }).then(res => {
          this.loading = false;
          this.addAssetsForm.dialogAddElectric = false;
          this.$message.success('添加成功');
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogAddElectricClose() {//添加资产弹窗关闭
        for (let i in this.addAssetsForm.assets) {
          this.addAssetsForm.assets[i] = '';
        }
        this.addAssetsForm.numbers = '';
        this.addAssetsForm.assetsPARMS = [];
        this.resetForm(['electric'], this);
        
      },
      uploadPhoto(){//上传图片
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.assetForm.homeRow.saId
        this.photoTableType='资产'
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
      classifyChange(){//根据资产类型获取设备名称
        this.addAssetsForm.assetsType=[]
        console.log(this.$store.state.companyVars.assetsTypeList)
        for(let i in this.$store.state.companyVars.assetsTypeList){
          if(this.addAssetsForm.assets.saClassify==this.$store.state.companyVars.assetsTypeList[i].type){
            this.addAssetsForm.assetsType=this.$store.state.companyVars.assetsTypeList[i].name
          }
        }
      },
      moveOutClick() {//迁出按钮
        if (!this.assetForm.homeRow) {
          this.$message.warning('请选择需要迁出的资产');
          return;
        }
        if(this.assetForm.homeRow.hsAddCommunity){
          this.moveOutForm.address=this.assetForm.homeRow.hsAddCommunity
        }else{
          this.moveOutForm.address=this.assetForm.homeRow.address
        }
        this.moveOutForm.dialogMoveOut = true;
      },
      handIdChange(val) {//经手人改变
        if (val) {
          this.moveOutForm.saAgent = val[2];
        }
      },
      saveMoveOutAss() {//迁出保存按钮
        if (!this.moveOutForm.saAgent) {
          this.$message.warning('请选择经手人');
          return;
        }
        let assetsPARMS = [];
        let obj = {
          saId: this.assetForm.homeRow.saId,
          saMoveFrom: this.homeRow.hsAddCommunity,
          saNumber: this.assetForm.homeRow.saNumber,
        };
        assetsPARMS.push(obj);
        let data2 = {
          saHouseStoreId: this.roomHsId,
          saAgent: this.moveOutForm.saAgent,
          moveReason: this.moveOutForm.moveReason,
          saMoveTo:this.roomAddress,
          assetsPARMS: assetsPARMS
        };
        this.loading = true;
        this.$axios({
          method: 'put',
          url: '/common1/companyAssets/move',
          data: data2
        }).then(res => {
          this.loading = false;
          this.moveOutForm.dialogMoveOut = false;
          this.$message.success('迁出成功');
          this.assetForm.homeRow={}
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogAssMoveOut(){//迁出资产弹窗关闭
        this.moveOutForm.moveReason = '';
        this.moveOutForm.handId = '';
        this.moveOutForm.moveFrom = '';
        this.roomAddress = ''
        this.roomHsId = ''
      },
      deleteRow(index, rows) {//添加资产删除按钮
        rows.splice(index, 1)
      },
      clearAssets(){//添加资产清除按钮
        for(let i in this.addAssetsForm.assets){
          this.addAssetsForm.assets[i]=''
        }
        this.$refs['electric'].resetFields()
        // this.resetForm(['electric'],this)
        this.addAssetsForm.numbers=null
      },
      homeTableDblclick(row){//资产表格双击
        this.assetForm.homeRow=row
        this.getAssFoll()
        this.assetForm.dialogElectric=true
      },
      getAssFoll(){//获取资产跟进
        this.assFollowData={}
        this.$axios({
          method:'get',
          url:'/common1/eventFollowupRecords',
          params:{
            limit:this.assFolLimit,
            cursor:this.assFolCur,
            jhfTableType:'资产',
            jhfTableId:this.assetForm.homeRow.saId
          }
        }).then(res=>{
          this.assFollowData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      assCurChange(cur){//资产跟进分页
        this.assFolCur=cur
        this.getAssFoll()
      },
      nextPrice(){//下一条
        let i=this.assetForm.homeRow.index+1
        if(i==this.assetForm.electricList.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.assetForm.homeRow=this.assetForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.assetForm.electricList[i])
        this.homeTableDblclick(this.assetForm.homeRow)
      },
      prvPrice(){//上一条
        let i=this.assetForm.homeRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.assetForm.homeRow=this.assetForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.assetForm.electricList[i])
        this.homeTableDblclick(this.assetForm.homeRow)
      },
      saFollowUpClick(row) {//跟进表格双击
        this.assetForm.dialogFollowup = true;
        this.assetForm.followupRow = row;
        console.log(row)
      },
      printCodeClick(){//打印资产标识卡
        let co=sessionStorage.getItem('co')
        this.printCodeForm.urlList=[]
        if(this.assetForm.homeSel.length==0){
          this.$message.warning('请勾选需要打印的资产')
          return
        }
        this.printCodeForm.selList.forEach(item=>{
          let obj={
            url: `http://fzz1.cn/b?c=${co}&a=${item.saId}`,
            number:item.saNumber,
            address:item.address,
            name:item.saName,
            status:item.saStatus,
            model:item.saModel,
            brand:item.saBrand
          } 
          this.printCodeForm.urlList.push(obj)
        })
        this.printCodeForm.dialogprintCode=true
      },
    //审批
      getApprovalPapers(t){//分页查询审批
        this.approvalParams.eaStoreId=this.homeRow.hsId
        if(t==1){
          this.approvalParams.cursor=1
        }
        this.approvalData = ''
        this.$axios({
          method:'get',
          url:'/common1/approvalPapers',
          params:this.approvalParams
        }).then(res=>{
          // if(res.data.result.records.length>0){
          //   res.data.result.records.forEach(item=>{
          //     item.useTime=this.taskCostTime(item.eaReleaseTime,item.eaUseTime)
          //   })
          // }
          this.approvalData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      approCurChange(cur){//审批分页
        this.approvalParams.cursor=cur
        this.getApprovalPapers()
      },
      addApproval(){//添加审批按钮
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.addAppro.eaApprovalNumber= this.randomNumber()
        this.dialogAppro=true
      },
      eaProcessChange(val){//审批流程选择框
        if(!val){
          return
        }
        this.eaEventHandler=[]
        if(val=='自由流程'){
          this.addAppro.eaEventType='自由流程'
        }else{
         let obj = this.appList.find((item)=>{//这里的appList就是上面遍历的数据源
            return item.jeapId == val;//筛选出匹配数据
          });
          console.log(obj)
          this.addAppro.eaEventContent=obj.jeapContent
          let hand=JSON.parse(obj.jeapProcess)[0]
          if(hand.storeId){
            this.eaEventHandler[0]=hand.storeId
          }
          if(hand.departmentId){
            this.eaEventHandler[1]=hand.departmentId
          }
          if(hand.handlerId){
            this.eaEventHandler[2]=hand.handlerId
          }
          console.log(this.eaEventHandler)
          this.addAppro.eaEventType=obj.jeapType
          if(this.eaEventHandler.length==2){
            let array = [];
            for (let j in this.userNameList) {
              if (this.eaEventHandler[0] ==this.userNameList[j].value) {
                array.push({
                  value:this.userNameList[j].value,
                  label:this.userNameList[j].label,
                  children:[]
                });
                for(let k in this.userNameList[j].children){
                  if(this.eaEventHandler[1] ==this.userNameList[j].children[k].value){
                    array[0].children.push(this.userNameList[j].children[k])
                  }
                }
              }
            }
            this.demoOpt = array;
            this.isArea = true;
          }else if(this.eaEventHandler.length==1){
            let array = [];
            for (let j in this.userNameList) {
              if (
                this.eaEventHandler[1] ==
                this.userNameList[j].value
              ) {
                array.push(this.userNameList[j]);
              }
            }
            this.demoOpt = array;
            this.isArea = true;
          }else{
            this.isArea = false;
          }
          console.log(this.demoOpt)
        }
      },
      uploadOfAppro(){//上传审批图片
        this.needSavePhoto=false//需要保存按钮
        this.photoTableId=''
        this.photoTableType='审批'
        this.dialogImgPath=true
      },
      //获取银行账号列表
      getBankAccData(t) {
        if(t==1){
          this.bankCur=1
        }
        this.$axios({
          url: `/common1/accountReceipt`,
          method: 'get',
          params: {
            csAccountName: this.scAccName,
            csBank: this.scBank,
            cursor: this.bankCur,
            limit: this.bankLim
          }
        }).then(res => {
          console.log(res);
          this.bankAccData = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        });
      },
      bankCurChange(cur){
        this.bankCur=cur
        this.getBankAccData()
      },
      //打开选择账号对话框
      selAcc() {
        this.getBankAccData(1);
        this.selBankAccDia = true;
      },
      //双击选择账号
      dbSelAcc(row) {
        console.log(row);
        this.addAppro.eaBankUsername = row.csAccountName;
        this.addAppro.eaBankName = row.csBank;
        this.addAppro.eaBankAccountNumber = row.csAccountUmber;
        this.addAppro.eaSubordinateBranch = row.csSubordinateBranch;
        this.addAppro.eaBankAccountDescription = row.csAccountDescription;
        this.selBankAccDia = false;
      },
      saveApproval(){//添加审批保存
        if(this.eaEventHandler.length==0){
          this.$message.warning('审批人不能为空')
          return
        }
        if(!this.addAppro.eaProcessId){
          this.$message.warning('审批类型不能为空')
          return
        }
        if(!this.addAppro.eaEventContent){
          this.$message.warning('审批内容不能为空')
          return
        }
        this.addAppro.addCommunity=this.homeRow.hsAddCommunity
        this.addAppro.eaProcessId=this.addAppro.eaProcessId=='自由流程'?'':this.addAppro.eaProcessId
        this.addAppro.eaStoreId=this.homeRow.hsId
        this.addAppro.priorityFlag=this.firstCheck?1:2
        this.addAppro.sendMessageFlag=this.msgChecked?1:2
        this.addAppro.randomNum=this.photoRandom
        this.addAppro.tableType='审批'
        this.addAppro.eaEventState='处理中'
        this.addAppro.eaHomeType='其他审批'
        this.addAppro.eaWhetherGenerateRecord='否'
        this.addAppro.eaEventHandler=this.eaEventHandler[2]
        this.loading=true
        this.$axios({
          method:'post',
          url:'/common1/approvalPapers',
          data:this.addAppro
        }).then(res => {
          this.loading = false;
          this.dialogAppro= false;
          this.$message.success('添加成功');
          this.getApprovalPapers()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogApproClose(){//审批弹窗关闭
        for(let i in this.addAppro){
          this.addAppro[i]=''
        }
        this.msgChecked=false
        this.firstCheck=false
        this.eaEventHandler=[]
      },
      approvalClick(row){//表格单击
        this.approvalRow=row
        console.log(row)
      },
      approvalDblClick(row){//表格双击
        this.eaTreatmentOpinion=[]
        this.approvalRow=row
        this.dialogAppDbl=true
        this.$axios({//单条查审批
          url: `/common1/approvalPapers/${row.eaId}`,
          method: 'get'
        }).then(res => {
          if (typeof res.data.result.eaTreatmentOpinion == 'string') {
            this.eaTreatmentOpinion = JSON.parse(`[${res.data.result.eaTreatmentOpinion}]`);
          }
        }).catch(err => {
          this.isError(err,this)
        })
        if(row.eaWhetherGenerateRecord=='是'){//通过流水号查付款详情
          this.$axios({
            method:'get',
            url:'/common1/financialRecords/list',
            params:{
              jfFinancialCoding:row.eaFinancialCoding
            }
          }).then(res=>{
            let obj=res.data.result.records[0]
            obj.faPaymentType=obj.faPaymentType?obj.faPaymentType:''
            obj.faUserName=obj.faUserName?obj.faUserName:''
            obj.faBelonging=obj.faBelonging?obj.faBelonging:''
            obj.faAccount=obj.faAccount?obj.faAccount:''
            this.approvalRow.payInfo=obj.faPaymentType+'-'+obj.faUserName+'-'+obj.faBelonging+'-'+obj.faAccount
            this.approvalRow.jfCheckInTime=obj.jfCheckInTime?obj.jfCheckInTime:''
          }).catch(err => {
          this.isError(err,this)
        })
        }
      },
      eaTreatmentDblclick(row){//审批进展双击
        this.dialogAppFol=true
        this.appFollowRow=row
      },
      approPrv(){//上一条
        let i=this.approvalRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.approvalRow=this.approvalData.records[i]
        this.$refs.appro.setCurrentRow(this.approvalData.records[i])
        this.approvalDblClick(this.approvalRow)
      }, 
      approNext(){//下一条
        let i=this.approvalRow.index+1
        if(i==this.approvalData.records.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.approvalRow=this.approvalData.records[i]
        this.$refs.appro.setCurrentRow(this.approvalData.records[i])
        this.approvalDblClick(this.approvalRow)
      },
      seePhotoClick(){//查看附件按钮
        if(!this.approvalRow.eaId){
          this.$message.warning('请选择一项')
          return
        }
        this.getPhotoByTableId()
        this.dialogSeeImgPath=true
      },
      async getPhotoByTableId(){//通过外表id查已有图片
        await this.$axios({
          method:'get',
          url:'/common1/photos',
          params:{
            photoTableId:this.approvalRow.eaId,
            photoTableType:'审批'
          }
        }).then(async res=>{
          if(res.data.result.length==0){
            return
          }
          let imgdata = res.data.result;
          for (let i = 0; i < imgdata.length; i++) {//将图片拼接为element识别格式
            if(imgdata[i].photoMimeType=='图片'){
              this.imgNum=this.imgNum+1
            }else if(imgdata[i].photoMimeType=='文件'){
              this.fileNum=this.fileNum+1
            }
            let obj = {
              photoStatus:imgdata[i].photoStatus,
              photoMimeType:imgdata[i].photoMimeType,
              name: imgdata[i].photoName,
              url:await this.getDownloadUrl(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            this.photoList.push(obj);
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDownloadUrl(url){//下载凭证获取图片路径
        return this.$axios({//下载凭证获取图片路径
          method:'get',
          url:'/common1/photos/getDownloadUrl',
          params:{
            baseUrls:url
          }
        }).then(res=>{
          return res.data.result[0].original
        }).catch(err => {
          this.isError(err,this)
        })
      },
    //任务
      getOddjobs(t){//分页查询任务
        if(t==1){
          this.taskCur=1
        }
        this.$axios({
          method:'get',
          url:'/common1/oddjobs',
          params:{
            limit:this.taskLim,
            cursor:this.taskCur,
            hsId:this.homeRow.hsId,
            oddjobsType:2
          }
        }).then(res=>{
          this.taskData=res.data.result
          if(this.taskData.records.length>0){
            this.taskData.records.forEach(item=>{
              if(!item.repFinishTime){
                item.repFinishTime='未完成'
              }
              if(!item.timeConsuming){
                item.timeConsuming='未领取'
              }
            })
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      taskCurChange(cur){//任务分页
        this.taskCur=cur
        this.getOddjobs()
      },
      addTaskClick(){//添加任务按钮
        this.dialogTask=true
        this.addTask.repContactsPhone=this.homeRow.houseEntrust4rent
        this.addTask.repContacts=this.homeRow.keyNumber
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
      },
      uploadOfTask(){//添加任务上传图片
        this.needSavePhoto=false//需要保存按钮
        this.photoTableId=''
        this.photoTableType='任务'
        this.dialogImgPath=true
      },
      saveTask(){//保存任务
        if(!this.repRepairPeopleId){
          this.$message.warning('请选择负责人')
          return
        }
        if(!this.addTask.repTypeRp){
          this.$message.warning('请选择任务类型')
          return
        }
        if(!this.addTask.repEventRp){
          this.$message.warning('任务描述不能为空')
          return
        }
        if(!this.addTask.repGrade){
          this.$message.warning('任务等级不能为空')
          return
        }
        this.addTask.repTaskTime=this.taskTime(this.addTask.repHopeTime)
        // if(this.addTask.repHopeTime=='本周末'){
        //   let time=moment().format('E')
        //   this.addTask.repHopeTime=moment().add(6-time, 'days').format('YYYY-MM-DD')+'或'+moment().add(7-time, 'days').format('YYYY-MM-DD')
        // }
        this.addTask.repResponsibility='负责人'
        this.addTask.repRepairPeopleId=this.repRepairPeopleId[2]
        this.addTask.address=this.homeRow.hsAddCommunity
        this.addTask.hsId=this.homeRow.hsId
        this.addTask.oddjobsType=2
        this.addTask.sendWxMessage='2'
        this.addTask.randomNum=this.photoRandom
        this.addTask.tableType='任务'
        this.loading=true
        this.$axios({
          method:'post',
          url:'/common1/oddjobs',
          data:this.addTask
        }).then(res => {
          this.loading = false;
          this.dialogTask= false;
          this.$message.success('添加成功');
          this.getOddjobs()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogTaskClose(){//添加任务弹窗关闭
        this.repRepairPeopleId=''
        this.addTask={
          repEventRp:'',
          repContactsPhone:'',
          repContacts:'',
          repHopeTime:'尽快',
          sendMessage:'1',
          repTaskTime:'',
          repGrade:'1',
        }
        this.taskCur=1
      },
      taskDataDblclick(row){//任务表格双击
        this.taskRow=row
        console.log(row)
        this.$axios({
          method:'get',
          url:`/common1/oddjobsFollowup/${this.taskRow.repId}`
        }).then(res=>{
          this.taskFollowData=res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
        this.dialogTaskDbl=true
      },
      taskUpload(){//查看任务上传图片
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.taskRow.repId
        this.photoTableType='任务'
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
      taskFollDblclick(row){//任务进展双击 
        this.dialogTaskFol=true
        this.taskFollowRow=row
      },
      taskPrv(){//上一条
        let i=this.taskRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.taskRow=this.taskData.records[i]
        this.$refs.task.setCurrentRow(this.taskData.records[i])
        this.taskDataDblclick(this.taskRow)
      }, 
      taskNext(){//下一条
        let i=this.taskRow.index+1
        if(i==this.taskData.records.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.taskRow=this.taskData.records[i]
        this.$refs.task.setCurrentRow(this.taskData.records[i])
        this.taskDataDblclick(this.taskRow)
      }, 
    //收支
      getRecords(t) {//分页查询收支记录
        if(t==1){
          this.commonQuery.cursor=1
        }
        this.commonQuery.hsId=this.homeRow.hsId
        this.$axios({
          url: '/common3/financialRecords',
          method: 'get',
          params: this.commonQuery
        }).then(res => {
          this.budgetData = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        });
      },
      budgetCurChange(cur){//收支分页
        this.commonQuery.cursor=cur
        this.getRecords()
      },
      budgetDblclick(row){//收支表格双击
        this.budgetRow=row
        this.dialogBudget=true
      },
    //弹窗关闭
      dialogInfoClose(){
        this.activeName=''
        this.suppliesParams.cursor=1
        this.assetForm.cursor=1
        this.approvalParams.cursor=1
        this.taskCur=1
        this.commonQuery.cursor=1
        Object.assign(this.$data.assetForm, this.$options.data().assetForm)
      } 
    }
  }
</script>

<style lang="scss" scoped>

</style>
